import React from "react";
import { useParams } from "react-router-dom";
import { useDb } from "../../../contexts/DatabaseContext";
import { useDocument } from "react-firebase-hooks/firestore";
import {
  Alert,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Stack,
} from "@mui/material";
import BackLink from "../../shared/BackLink";
import { MobileFriendly, Person } from "@mui/icons-material";
import UserMetaInfo from "./UserMetaInfo";
import RegDetailCard from "../registrations/RegDetailCard";
import UserTransactionsPreview from "./UserTransactionsPreview";

export default function UserDetail() {
  const { userId } = useParams();
  const { getDocRef, collectionNames, updateDocument } = useDb();
  const [snapshot, loading, error] = useDocument(
    getDocRef(collectionNames.users, userId)
  );

  const user =
    (snapshot && {
      ...snapshot.data(),
      id: snapshot.id,
    }) ||
    {};

  const handleUnblock = async () => {
    const confirm = window.confirm(
      "Are you sure you want to unblock this user? "
    );
    if (confirm) {
      await updateDocument(collectionNames.users, userId, {
        blocked: false,
        registration_complete: false,
      });
    }
  };

  if (loading) {
    return <LinearProgress />;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  return (
    <div>
      <BackLink />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Stack spacing={1}>
            <Card>
              <CardHeader
                title={"User Details"}
                avatar={
                  <Avatar>
                    <Person />
                  </Avatar>
                }
              />
              <CardContent>
                <Stack spacing={1}>
                  <RegDetailCard
                    textTitle={user.phone_number}
                    subheader={"User Phone Number"}
                    icon={<MobileFriendly />}
                  />
                  {user.blocked && (
                    <Alert
                      severity="error"
                      action={
                        <Button onClick={handleUnblock} variant="contained">
                          Unblock
                        </Button>
                      }
                    >
                      User is blocked.
                    </Alert>
                  )}
                </Stack>
              </CardContent>
            </Card>
            <UserMetaInfo uid={userId} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <UserTransactionsPreview uid={userId} />
        </Grid>
      </Grid>
    </div>
  );
}
